import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class CampaingService {
  apiURL = environment.apiBBDDURL;
  apiHubURL = environment.apiHub.URL;
  public firstPage: string = "";
  public prevPage: string = "";
  public nextPage: string = "";
  public lastPage: string = "";

  constructor(private httpClient: HttpClient) { }

  private headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });


  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  }

  httpApiHubOptions = {
    headers: new HttpHeaders({
      'Authorization': 'Basic ' + environment.apiHub.apiKey,
      'Content-Type': 'application/json'
    })
  }

  httpApiHubOptionsURLEncoded = {
    headers: new HttpHeaders({
      'Authorization': 'Basic ' + environment.apiHub.apiKey,
      'Content-Type': 'application/x-www-form-urlencoded' 
    })
  }

  public getCampaings(date : string, branchId: string, mtype :string, limitRecord?: string, offset?: string): Observable<any> {

    let body = new URLSearchParams();
    body.set('branchid', branchId);
    body.set('date',date);
    body.set('mtype', mtype);
    body.set('limitRecord', limitRecord);
    body.set('offset', offset);

    return this.httpClient.post(this.apiHubURL + 'campaign/getHistory', body.toString(), this.httpApiHubOptionsURLEncoded);
  }
  
  public sentInstantShipping(objectForm : any): Observable<any> {
    console.log('objectForm',objectForm)
    let body = new URLSearchParams();
    body.set('branchId', objectForm.branchId);
    body.set('numbers',objectForm.receivers);
    body.set('message',objectForm.message);
    body.set('mtype',objectForm.mtype);
    body.set('format','INTL');
  
    return this.httpClient.post(this.apiHubURL + 'dashboard/sentinstantshipping', body.toString(), this.httpApiHubOptionsURLEncoded);
  }

  public csvShipping(objectForm : any): Observable<any> {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data'
      })
    }
  
    return this.httpClient.post(this.apiURL + 'sendcsvshipping', objectForm);
  }

  public getSentMessages(campId :string, date : string, limitRecord?: string, offset?: string): Observable<any> {

    let body = new URLSearchParams();
    body.set('campid', campId);
    body.set('date',date);
    body.set('limitRecord', limitRecord);
    body.set('offset', offset);

    return this.httpClient.post(this.apiHubURL + 'campaign/getsentmessages', body.toString(), this.httpApiHubOptionsURLEncoded);
  }

  public getVariables(branchId: string, limitRecord?: string, offset?: string): Observable<any> {

    let body = new URLSearchParams();
    body.set('branchid', branchId);
    body.set('limitRecord', limitRecord);
    body.set('offset', offset);

    return this.httpClient.post(this.apiURL + 'getvariables', body.toString(), this.httpOptions);
  }


  public getOptinOptions(): Observable<any> {
    return this.httpClient.post(this.apiHubURL + 'contact/getOptinStatOptions', '', this.httpApiHubOptions);
  }

  public getCampOptions(): Observable<any> {
    return this.httpClient.post(this.apiHubURL + 'campaign/getCampOptions', '', this.httpApiHubOptions);
  }

  public bulkMessage(ObjJson): Observable<any> {
    return this.httpClient.post(this.apiHubURL + 'message/bulkMessage', ObjJson, this.httpApiHubOptions);
  }
  

  public getAppoi(channel: string): Observable<any> {

    let body = new URLSearchParams();

    return this.httpClient.post(this.apiHubURL + 'dashboard/getNotifications', {}, this.httpApiHubOptionsURLEncoded);
  }

  public createVariable(objectContact): Observable<any> {

    let body = new URLSearchParams();
    body.set('name', objectContact.name);
    body.set('branchid', objectContact.branchId);

    return this.httpClient.post(this.apiURL + 'new_variable', body.toString(), this.httpOptions);
  }

  public getVariableDetails(idVariable: string): Observable<any> {
    let body = new URLSearchParams();
    body.set('id', idVariable);

    return this.httpClient.post(this.apiURL + 'getvariable_details', body.toString(), this.httpOptions);
  }

  public updateVariable(objectVariable): Observable<any> {
    let body = new URLSearchParams();
    body.set('id', objectVariable.id);
    body.set('name', objectVariable.name);
    body.set('branchId', objectVariable.branchId);

    return this.httpClient.post(this.apiURL + 'updatevariable', body.toString(), this.httpOptions);
  }

  public deleteVariable(idVariable): Observable<any> {
    let body = new URLSearchParams();
    body.set('idVariable', idVariable);

    return this.httpClient.post(this.apiURL + 'deletevariable', body.toString(), this.httpOptions);
  }

  public getBranches(username): Observable<any> {
    return this.httpClient.post(this.apiHubURL + 'dashboard/getuserbranchoffice', { username }, this.httpApiHubOptions);
  }

  public getSessionsByChannel(channel: string, date: string, branchId: string): Observable<any> {

    let body = new URLSearchParams();
    body.set('channel', channel);
    body.set('date', date);
    body.set('branchid', branchId);

    return this.httpClient.post(this.apiURL + 'sessionsByChannel', body.toString(), this.httpOptions);
  }

  public getSessionsWebOff(channel: string, date: string, branchId: string): Observable<any> {

    let body = new URLSearchParams();
    body.set('channel', channel);
    body.set('date', date);
    body.set('branchid', branchId);

    return this.httpClient.post(this.apiHubURL + 'chatbot/sessions_web_off', body.toString(), this.httpApiHubOptionsURLEncoded);
  }

  public getSessionsByChannelOffset(date: string, limitRecord: string, offset: string, branchId: string, channel?: string): Observable<any> {

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    let body = new URLSearchParams();
    body.set('date', date);
    body.set('limitRecord', limitRecord);
    body.set('offset', offset);
    body.set('branchid', branchId);

    if (channel) {
      body.set('channel', channel);
    }

    return this.httpClient.post(this.apiHubURL + 'chatbot/sessionsByChannelOffset', body.toString(), this.httpApiHubOptionsURLEncoded);
  }

  public updateViewStatus(tokenId: string, branchOfficeId: string): Observable<any> {

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    let body = new URLSearchParams();
    body.set('tokenid', tokenId);

    return this.httpClient.post(this.apiHubURL + 'chatbot/updateView', body.toString(), this.httpApiHubOptionsURLEncoded);
  }

  public getInteractionByToken(tokenId: string): Observable<any> {

    let body = new URLSearchParams();
    body.set('id', tokenId);

    return this.httpClient.post(this.apiHubURL + 'chatbot/getwebinteractionsbytoken', body.toString(), this.httpApiHubOptionsURLEncoded);
  }

  public getSmschat(dateRef: string, branchId : string, mtype : string, phone : string): Observable<any> {

    let body = new URLSearchParams();
    body.set('date',dateRef);
    body.set('branchId', branchId);
    body.set('mtype', mtype);
    body.set('phone', phone);

    return this.httpClient.post(this.apiHubURL + 'dashboard/getsmschats', body.toString(), this.httpApiHubOptionsURLEncoded);
  }
  
  public getMessagesByPhone(phone: string, branchId : string, mtype : string): Observable<any> {

    let body = new URLSearchParams();
    body.set('phone', phone);
    body.set('branchId', branchId);
    body.set('mtype', mtype);

    return this.httpClient.post(this.apiHubURL + 'dashboard/getsmschatdetails', body.toString(), this.httpApiHubOptionsURLEncoded);
  }
  
  public sendMTResponse(to: string, from : string, message : string, branchId : string, mtype: string): Observable<any> {

    let body = new URLSearchParams();
    body.set('toNumber', to);
    body.set('from', from);
    body.set('message', message);
    body.set('branchId', branchId);
    body.set('type', mtype);
    body.set('format', 'INTL');

    return this.httpClient.post(this.apiHubURL + 'message/sms', body.toString(), this.httpApiHubOptionsURLEncoded);
  }
  // Error handling 
  // public errHandl(error) {
  //   let errorMessage = '';
  //   if (error.error instanceof ErrorEvent) {
  //     // Get client-side error
  //     errorMessage = error.error.message;
  //   } else {
  //     // Get server-side error
  //     errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  //   }
  //   console.error(errorMessage);
  //   return throwError(errorMessage);
  // }
}